import React, { useEffect, useMemo, useState } from "react";
import "./start-tg-bot.scss";
import DefaultModal from "../DefaultModal";
import { useAppSelector } from "../../../store/index.ts";
import useExternalLink from "../../../hooks/common/useExternalLink";

export default function StartTgBotModal({ isOpen, closeModal }) {
  const startBot = useAppSelector((state) => state.promotions.startBot);
  const currentLang = useAppSelector((state) => state.common.lang);
  const isMobile = useAppSelector((state) => state.deviceSize.isMobile);
  const { playTg } = useExternalLink();

  const currentImage = useMemo(() => {
    const banners = startBot?.langs?.[currentLang];

    if (!banners) return null;

    const curImage = isMobile ? banners?.mobile_image : banners?.desktop_image;
    return curImage;
  }, [isMobile, startBot, currentLang]);

  const [show, setShow] = useState();

  const hideShow = () => {
    setShow(false);
    closeModal();
  };

  const onImgClick = () => {
    window.open(playTg, "_blank");
  };

  useEffect(() => {
    console.log("isOpen", isOpen);
    console.log("currentImage", currentImage);

    setShow(isOpen && currentImage);
  }, [isOpen, currentImage]);

  return (
    <DefaultModal isOpen={show} closeModal={hideShow} classModal="start-tg-bot__modal">
      <img src={currentImage} onClick={onImgClick} />
    </DefaultModal>
  );
}
