import { useAppSelector } from "../../store/index.ts";
import useCurrentBalance from "../useCurrentBalance.js";
import { useEventListener, useReadLocalStorage } from "usehooks-ts";
import {
  setModalBalance,
  setModalLogin,
  setTypeLogin,
  setStartTgBot
} from "../../store/siteSlice.js";
import { useDispatch } from "react-redux";
import { ENUM_LCS_NAME, ENUM_LOGIN } from "../../store/enums.js";
import { useEffect, useRef } from "react";
import { dispatchCusEvent } from "../../utils/utils.js";
import { ENUM_TAWK_API } from "../other/useTawkController.js";

export const DELAY_TM_1 = 60 * 1000;
export const DELAY_TM_2 = 90 * 1000;
const DELAY_WELCOME_TG_BOT = 180 * 1000;

/**
 * что бы лишний раз не мучаться с логикой таймаутов в реакте,
 * сделано через кастомные события
 */
export default function useModalShowLogic() {
  const dispatch = useDispatch();
  const alreadyAccount = useReadLocalStorage(ENUM_LCS_NAME.alreadyAccount);
  const userId = useAppSelector((state) => state.user.userId);
  const isTgBot = useAppSelector((state) => state.common.isTgBot);
  const modals = useAppSelector((state) => state.site.modals);
  const isMobile = useAppSelector((state) => state.deviceSize.isMobile);
  const isRunningGame = useAppSelector((state) => state.site.isRunningGame);
  const isAuth = useAppSelector((state) => state.user.isAuth);

  const { balances } = useCurrentBalance();
  const tmRef = useRef();
  const hideTawkRef = useRef();
  const isRunningGameRef = useRef();
  const alreadyShowBannerTg = useRef();
  const tmShowBannerTg = useRef();

  const checkOpenModal = () => {
    const logicModal = () => {
      if (openAnyModals) return;
      if (isRunningGameRef.current) return;

      if (userId) {
        if (balances.main) return;

        dispatch(setModalBalance(true));
        return;
      }

      dispatch(setTypeLogin(alreadyAccount ? ENUM_LOGIN.login : ENUM_LOGIN.register));
      dispatch(setModalLogin(true));
    };

    const openAnyModals = Object.values(modals).some((el) => el === true);
    if (isTgBot) return;

    logicModal();
    clearTimeout(tmRef.current);
    tmRef.current = setTimeout(() => {
      dispatchCusEvent(ENUM_LCS_NAME.checkOpenModal);
    }, DELAY_TM_1);
  };

  useEventListener(ENUM_LCS_NAME.checkOpenModal, () => checkOpenModal());

  useEffect(() => {
    isRunningGameRef.current = isRunningGame;
  }, [isRunningGame]);

  useEffect(() => {
    console.log("userid", userId);

    if (userId) dispatch(setModalLogin(false));
  }, [userId]);

  useEffect(() => {
    if (tmRef.current) {
      clearTimeout(tmRef.current);
      tmRef.current = setTimeout(() => {
        dispatchCusEvent(ENUM_LCS_NAME.checkOpenModal);
      }, DELAY_TM_2);
      return;
    }

    tmRef.current = setTimeout(() => {
      dispatchCusEvent(ENUM_LCS_NAME.checkOpenModal);
    }, DELAY_TM_1);

    return () => clearTimeout(tmRef.current);
  }, []);

  useEffect(() => {
    const openModals = Object.values(modals).some((el) => el === true);

    // если не мобильный, то выходим
    if (!isMobile) return;

    // если открытась модалка и виджет был вклбчен
    if (openModals && window.tawk_INFO.visible) {
      dispatchCusEvent(ENUM_TAWK_API.hide);
      hideTawkRef.current = true;
    }

    // если модалка закрылась а виджет включился из текущий логики
    if (!openModals && hideTawkRef.current) {
      dispatchCusEvent(ENUM_TAWK_API.show);
      hideTawkRef.current = false;
    }
  }, [modals, isMobile]);

  useEffect(() => {
    if (isTgBot) return;

    console.log("isAuth", isAuth);

    // если вышли то очищаем таймер и обнуляем
    if (!isAuth) {
      clearTimeout(tmShowBannerTg.current);
      return;
    }

    console.log("alreadyShowBannerTg", alreadyShowBannerTg);
    if (alreadyShowBannerTg.current) return;

    tmShowBannerTg.current = setTimeout(() => {
      alreadyShowBannerTg.current = true;
      dispatch(setStartTgBot(true));
    }, DELAY_WELCOME_TG_BOT);
  }, [isAuth, isTgBot]);
}
