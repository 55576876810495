import React, { useMemo, useState } from "react";
import ShowContainer from "../ShowContainer";
import IconFavorite from "../JSXIcons/IconFavorite";
import { useTranslation } from "react-i18next";
import "./game-item.scss";
import { useDispatch, useSelector } from "react-redux";
import { API_USER } from "../../../api/user";
import { DEBUG, TEST_MODE } from "../../../store/enums";
import { addFavoriteGames, removeFavoriteGames } from "../../../store/gamesSlice";
import IconProviders from "../JSXIcons/IconProviders";
import useGameClick from "../../../hooks/other/useGameClick";
import { errorToast } from "../Toaster";
import useLoginAction from "../../../hooks/useLoginAction";
import { ENUM_COMMON_EVENT } from "../../../hooks/common/useListener";

export default function GameItem({
  className = "game",
  url = "/game/",
  image,
  imageHover = "",
  hideFavorites,
  name,
  author,
  urlParam,
  isChoose = true,
  currentGame,
  isCarousel
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onLoginAction } = useLoginAction();

  const favoriteGamesId = useSelector((state) => state.games.favoriteGamesId);

  const id = useMemo(() => currentGame.game_id || currentGame.id, [currentGame]);
  const isFavorite = useMemo(() => favoriteGamesId.includes(id), [favoriteGamesId, id]);
  const isFavoriteClass = useMemo(() => (isFavorite ? "favorite" : ""), [isFavorite]);

  const [hover, setHover] = useState(false);

  // TODO - временно скрыто
  // const countOnline = 0;

  const { onGameClick } = useGameClick();

  const onItemGameClick = () => {
    onGameClick({ currentGame, isChoose, url, urlParam });
  };

  const onFavorites = () => {
    console.log("favoriteGamesId", favoriteGamesId);

    !isFavorite
      ? dispatch(addFavoriteGames(currentGame))
      : dispatch(removeFavoriteGames(currentGame));

    if (TEST_MODE) return;

    API_USER.toggleFavorites(id)
      .then((e) => {
        if (DEBUG) console.log("favorites res", e);
        const isFavorites = e.data.favorites;
        isFavorites
          ? dispatch(addFavoriteGames(currentGame))
          : dispatch(removeFavoriteGames(currentGame));
      })
      .catch((e) => {
        errorToast(e.message);
        isFavorite
          ? dispatch(addFavoriteGames(currentGame))
          : dispatch(removeFavoriteGames(currentGame));
        if (DEBUG) console.log("catch", e);
      });
  };

  const provider = currentGame.provider;
  const carouselClass = isCarousel ? "carousel-item" : "";

  return (
    <div
      className={`${className}__item ${carouselClass}`}
      onClick={onItemGameClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <div className={`${className}__item__photo`}>
        <img className={`${className}__img`} src={imageHover && hover ? imageHover : image} />
        {/* <ShowContainer condition={!!countOnline}>
          <span className={`${className}__item__online`}>
            <IconPeople2 />
            <span>{countOnline}</span>
          </span>
        </ShowContainer> */}
        <ShowContainer condition={!hideFavorites}>
          <button
            id={id}
            data-clickid={ENUM_COMMON_EVENT.favoritesClick}
            className={`${className}__item__favorite ${isFavoriteClass}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onLoginAction(onFavorites);
            }}>
            <IconFavorite />
          </button>
        </ShowContainer>
      </div>
      <ShowContainer condition={provider}>
        <p className={`${className}__item__provider`}>
          <IconProviders />
          <span>{provider}</span>
        </p>
      </ShowContainer>
      <ShowContainer condition={name}>
        <p className={`${className}__item__name`}>{t(name)}</p>
      </ShowContainer>
      <ShowContainer condition={author}>
        <span className={`${className}__item__author`}>{t(author)}</span>
      </ShowContainer>
    </div>
  );
}
