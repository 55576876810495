import React from "react";
import ChooseCurrency from "../../../mui/choose_currency/ChooseCurrency";
import ShowContainer from "../../../mui/ShowContainer";
import CusButton from "../../../mui/custom_button/CusButton";
import IconCirclePlus from "../../../mui/JSXIcons/IconCirclePlus";
import { useDispatch, useSelector } from "react-redux";
import IconCircleMinus from "../../../mui/JSXIcons/IconCircleMinus";
import { setModalBalance, setPromoCode, setWithdrawal } from "../../../../store/siteSlice";
import AvatarPopup from "./avatar_popup/AvatarPopup";
import useLoginAction from "../../../../hooks/useLoginAction";
import ChooseCurrencyFull from "../../../mui/choose_currency/ChooseCurrencyFull";

export default function HeaderAuthMenu() {
  const dispatch = useDispatch();
  const { onLoginAction } = useLoginAction();
  const isMenuAbsolute = useSelector((state) => state.deviceSize.isMenuAbsolute);

  return (
    <>
      <div className="header__auth__auth">
        {isMenuAbsolute ? <ChooseCurrencyFull /> : <ChooseCurrency />}
        <ShowContainer condition={!isMenuAbsolute}>
          <CusButton
            border
            icon={<IconCirclePlus />}
            className="plus"
            color="custom"
            text={"Replenish"}
            onClick={() => onLoginAction(() => dispatch(setModalBalance(true)))}
          />
          <CusButton
            className="minus"
            icon={<IconCircleMinus />}
            text={"Withdraw"}
            onClick={() => onLoginAction(() => dispatch(setWithdrawal(true)))}
          />
          <CusButton
            className="promo"
            text={"Promo codes"}
            onClick={() => onLoginAction(() => dispatch(setPromoCode(true)))}
          />
        </ShowContainer>
      </div>
      <AvatarPopup />
    </>
  );
}
