import React, { lazy } from "react";
import { ENUM_GAME_TYPE } from "../store/enums";
import PageWitchMenu from "../components/mui/page_witch_menu/PageWitchMenu";
import useScrollUp from "../hooks/useScrollUp";
import ErrorBoundary from "../components/mui/error_boundary/ErrorBoundary";
import LoaderContainer from "../components/mui/loader_container/LoaderContainer";
import { useAppSelector } from "../store/index.ts";
import CusSuspence from "../components/mui/CusSuspence.jsx";
import Banners from "../components/landing/banners/Banners";
import Header from "../components/landing/header/Header.jsx";
import CatalogGame from "../components/landing/catalog_game/CatalogGame";
import "./main.scss";

const TypeGamesMenu = lazy(() => import("../components/landing/type_games_menu/TypeGamesMenu"));
const LastChampions = lazy(() => import("../components/landing/last_champions/LastChampions"));
const Jackpot = lazy(() => import("../components/landing/jackpot/Jackpot"));
const FindGameLine = lazy(() => import("../components/landing/find_game_line/FindGameLine"));
const Favorites = lazy(() => import("../components/landing/favorites/Favorites"));
const Footer = lazy(() => import("../components/landing/footer/Footer"));

export default function Main() {
  useScrollUp();
  const loader = useAppSelector((state) => state.site.mainLoader);
  const errorInfo = useAppSelector((state) => state.site.errorInfo);

  return (
    <LoaderContainer center loader={loader || errorInfo} fullHeight>
      <PageWitchMenu className={"landing"}>
        <ErrorBoundary>
          <Header />
        </ErrorBoundary>
        <CusSuspence fallback={<div style={{ minHeight: 100 }} className="last-champion"></div>}>
          <LastChampions />
        </CusSuspence>
        <ErrorBoundary>
          <Banners />
        </ErrorBoundary>
        <CusSuspence>
          <Jackpot />
        </CusSuspence>
        <CusSuspence>
          <TypeGamesMenu activeTab={ENUM_GAME_TYPE.lobby} />
        </CusSuspence>
        <CusSuspence>
          <FindGameLine />
        </CusSuspence>
        <ErrorBoundary>
          <CatalogGame />
        </ErrorBoundary>
        <CusSuspence>
          <Favorites />
        </CusSuspence>
        {/* <LatestRates /> */}
        <CusSuspence>
          <Footer />
        </CusSuspence>
      </PageWitchMenu>
    </LoaderContainer>
  );
}
