import React, { useEffect, useState } from "react";
import imgCardCards from "./type_image/cards.png";
import imgCarRub from "./type_image/rub.png";
import imgCardSber from "./type_image/sberPay.png";
import imgCardSbp from "./type_image/sbp.png";
import imgCardUsdt from "./type_image/usdt.png";
import imgCardHumo from "./type_image/humo.png";
import imgCardUzcard from "./type_image/uzcard.png";
import imgQrcode from "./type_image/qrcode.png";
import imgKapitalBank from "./type_image/kb.png";
import imgM10 from "./type_image/m10.png";
import imgVisaMc from "./type_image/visaMC.png";
import imgMpay from "./type_image/mpay.png";
import "./top-up-cards.scss";
import { API_USER } from "../../../../api/user";
import { errorToast } from "../../../mui/Toaster";
import { DEBUG, ENUM_CURRENCY } from "../../../../store/enums";
import { messageByCode } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";
import LoaderContainer from "../../../mui/loader_container/LoaderContainer";

import { useAppSelector } from "../../../../store/index.ts";
import { setLockBalanceClose } from "../../../../store/siteSlice.js";
import { useDispatch } from "react-redux";

export default function TopUpCards({
  amount,
  currency,
  closeModal,
  setLockedInfo,
  setOpenPaymentPhone,
  setOpenPaymentCard
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isIos = useAppSelector((state) => state.deviceSize.isIos);
  const [cards, setCard] = useState([]);
  const [loader, setLoader] = useState(false);

  const cardsByIdCommon = {
    sber: { id: "sber", img: imgCardSber, title: "To Sberbank account" },
    rub: { id: "rub", img: imgCarRub, title: "Transfer to account" },
    usdt: { id: "usdt", img: imgCardUsdt, title: "USDT" },
    qrc: { id: "qrc", img: imgQrcode, title: "QR code" },
    sbp: { id: "sbp", img: imgCardSbp, title: "SBP by phone number" }
  };

  const cardsById = {
    ...cardsByIdCommon,
    card: { id: "card", img: imgCardCards, title: "Transfer to bank card" },
    "card.HUMO": { id: "card.HUMO", img: imgCardHumo, title: "Humo" },
    "card.UZCARD": { id: "card.UZCARD", img: imgCardUzcard, title: "Uzcard" },
    "sbp.m10": { id: "sbp.m10", img: imgM10, title: "M10" },
    "sbp.mpay": { id: "sbp.mpay", img: imgMpay, title: "MPAY" },
    kapitalBank: { id: "kapitalBank", img: imgKapitalBank, title: "Transfer to Kapital Bank card" },
    // visaMc: { id: "card.visa", img: imgVisaMc, title: "Transfer to bank card" },
    visaMc: { id: "card", img: imgVisaMc, title: "Transfer to bank card" }
  };

  const addMethodAZN = (list) => {
    const newCards = list?.map((el) => cardsByIdCommon[el]) || [];

    if (list.includes("card")) {
      newCards.push({ ...cardsById.visaMc, id: "card" });
      newCards.push({ ...cardsById.kapitalBank, id: "card" });
    }

    if (list.includes("sbp.m10")) newCards.push(cardsById["sbp.m10"]);
    if (list.includes("sbp.mpay")) newCards.push(cardsById["sbp.mpay"]);

    return newCards.filter((el) => el) || [];
  };

  useEffect(() => {
    if (!loader && cards.length == 0) {
      dispatch(setLockBalanceClose(false));
    }
  }, [cards, loader]);

  /**
   * @description - получение списка методов
   */
  useEffect(() => {
    setLoader(true);
    API_USER.balance
      .method({ amount, currency })
      .then((e) => {
        if (DEBUG) console.log(e);
        if (e?.dataError) return errorToast(t(messageByCode(e.dataError)));

        const list = e.data?.list;

        if (currency === ENUM_CURRENCY.AZN) {
          setCard(addMethodAZN(list));
          return;
        }
        const cards = list?.map((el) => cardsById[el]) || [];
        console.log("cards", cards);

        setCard(cards.filter((el) => el) || []);
      })
      .catch(console.error)
      .finally(() => {
        setLoader(false);
      });
  }, [amount, currency]);

  /**
   * @description - выбор нужного метода
   */
  const onCardClick = (method) => {
    setLoader(true);

    API_USER.balance
      .replenish({ amount, currency, method })
      .then((e) => {
        if (DEBUG) console.log("make deposit res", e);
        const data = e.data;

        if (data.depositLockInfo) setLockedInfo(data);
        if (data?.dataError) return errorToast(t(messageByCode(data.dataError)));
        if (data?.error)
          return errorToast(t("It was not possible to issue payment details. Try again."));
        if (data?.paymentUrl) {
          isIos ? (window.location.href = data.paymentUrl) : window.open(data.paymentUrl, "_blank");

          closeModal();
        }

        if (data.phonePayment) setOpenPaymentPhone({ ...data.phonePayment, sum: amount, currency });
        if (data.cardPayment) setOpenPaymentCard({ ...data.cardPayment, sum: amount, currency });
      })
      .catch((e) => {
        if (DEBUG) console.log("make deposit cath", e);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <LoaderContainer loader={loader} center>
      {cards?.length ? (
        <div className="top-up-balance__cards">
          {cards.map((card, indx) => (
            <div key={indx} className="card" onClick={() => onCardClick(card.id)}>
              <img src={card.img} />
              <span>{t(card.title)}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className="top-up-balance__cards__empty">
          <h3>{t("No methods available")}</h3>
        </div>
      )}
    </LoaderContainer>
  );
}
