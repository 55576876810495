import { createSlice } from "@reduxjs/toolkit";
import { TEST_MODE } from "./enums";

const promotionsSlice = createSlice({
  name: "promotion",
  initialState: {
    forUser: TEST_MODE ? +localStorage.getItem("userId") : "loading",
    currency: null,
    cashback: null,
    firstDeposit: null,
    registration: null,
    startBot: null,
    jackpots: {
      // mini: {},
      // major: {},
      // grand: {}
    }
  },
  reducers: {
    setCashback(state, newAction) {
      state.cashback = newAction.payload;
    },
    setBonusCurrency(state, newAction) {
      state.currency = newAction.payload;
    },
    setFirstDeposit(state, newAction) {
      state.firstDeposit = newAction.payload;
    },
    setRegistration(state, newAction) {
      state.registration = newAction.payload;
    },
    setStartBot(state, newAction) {
      state.startBot = newAction.payload;
    },
    setForUser(state, newAction) {
      state.forUser = newAction.payload;
    },
    setJackpots(state, newAction) {
      state.jackpots = newAction.payload;
    }
  }
});
export default promotionsSlice.reducer;

export const {
  setCashback,
  setFirstDeposit,
  setRegistration,
  setStartBot,
  setForUser,
  setJackpots,
  setBonusCurrency
} = promotionsSlice.actions;
