import React, { useEffect, useRef } from "react";
import { useAppSelector } from "../store/index.ts";

export default function LiveChat() {
  const lang = useAppSelector((state) => state.common.lang);
  const liveChatData = useAppSelector((state) => state.common.liveChatData);
  const isTgBot = useAppSelector((state) => state.common.isTgBot);

  const liveChatRef = useRef();
  const alreadyInitRef = useRef(false);

  const onTawkBeforeLoad = () => {
    const myVisitor = window.tawk_INFO.visitor;
    window.tawk_INFO.visible ? window.Tawk_API.showWidget() : window.Tawk_API.hideWidget();

    if (myVisitor.name || myVisitor.email) {
      window.Tawk_API.visitor = {
        name: myVisitor?.name || "",
        email: myVisitor?.email || ""
      };
    }

    console.log("onTawkBeforeLoad");
  };

  useEffect(() => {
    if (alreadyInitRef.current) return;
    if (isTgBot) return;
    if (!liveChatData) return;

    const tawkParams = liveChatData[lang];
    if (!tawkParams?.propertyId) return;

    const propertyId = tawkParams.propertyId;
    const widgetId = tawkParams.widgetId;

    liveChatRef.current.innerHTML = "";
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://embed.tawk.to/${propertyId}/${widgetId}`;
    script.setAttribute("crossorigin", "*");
    liveChatRef.current.appendChild(script);

    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();
    window.Tawk_API.onBeforeLoad = onTawkBeforeLoad;
    alreadyInitRef.current = true;
  }, [isTgBot, liveChatData, lang]);

  return <div id="container-live-chat" ref={liveChatRef}></div>;
}
