import React from "react";
import "./banner-item.scss";
import { useDispatch } from "react-redux";
import useNavigateWithSearch from "../../../../hooks/useNavigateWitchSearch";
import { setBannerPosition } from "../../../../store/siteSlice";
import useExternalLink from "../../../../hooks/common/useExternalLink";
import { useAppSelector } from "../../../../store/index.ts";

const ENUM_GAME_TYPE = {
  INTEGRATED: 1,
  BONUS: 2
};

const videoTypes = [".webm", ".avi", ".mp4", ".ogg"];

export default function BannerItem({
  id,
  gameId,
  gameType,
  fileUrl,
  mobileFileUrl,
  externalUrl,
  relativeUrl,
  position,
  makeBotLink
}) {
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();
  const isExtraSmall = useAppSelector((state) => state.deviceSize.isExtraSmall);
  const botName = useAppSelector((state) => state.common.botName);
  const isTgBot = useAppSelector((state) => state.common.isTgBot);

  const { playTg } = useExternalLink();

  const currentUrl = isExtraSmall ? mobileFileUrl || fileUrl : fileUrl;

  const isVideo = videoTypes.some((el) => currentUrl.includes(el));

  const onClick = () => {
    const getUrl = () => {
      if (gameId && gameType) {
        if (gameType == ENUM_GAME_TYPE.INTEGRATED) return "/game/" + gameId;
        if (gameType == ENUM_GAME_TYPE.BONUS) return "/bonus_game/" + gameId;
      }
    };

    console.log("makeBotLink", makeBotLink, !isTgBot && botName, playTg);

    if (!isTgBot && botName && makeBotLink) {
      window.open(playTg, "_blank");
      return;
    }

    const url = getUrl();
    if (url) return navigate(url);

    if (relativeUrl) {
      dispatch(setBannerPosition(position));
      return navigate("/banner/" + relativeUrl);
    }
    if (externalUrl) return window.open(externalUrl, "_blank");
  };

  // useEffect(() => {
  // CRUTCH
  //   document.addEventListener("dragstart", (event) => {
  //     if (event.target.tagName === "video") event.preventDefault();
  //     if (event.target.tagName === "VIDEO") event.preventDefault();
  //   });
  //   window.ondragstart = function () {
  //     return false;
  //   };
  // }, []);

  return (
    <div className="banner-item" id={id} onClick={onClick}>
      <div className="banner-item__content">
        {isVideo ? (
          <video
            className="banner-item__video"
            src={currentUrl}
            autoPlay
            loop
            playsInline
            muted
            draggable={false}
          />
        ) : (
          <img className="banner-item__img" src={currentUrl} />
        )}
      </div>
    </div>
  );
}
