import React, { useRef } from "react";
import "./chose-lang.scss";
import { useDispatch } from "react-redux";
import IconFlagRu from "../JSXIcons/flag/IconFlagRu";
import IconFlagEn from "../JSXIcons/flag/IconFlagEn";
import IconArrow from "../JSXIcons/IconArrow";
import ShowContainer from "../ShowContainer";
import { setLang } from "../../../store/commonSlice";
import IconFlagUz from "../JSXIcons/flag/IconFlagUz";
import IconFlagAr from "../JSXIcons/flag/IconFlagAr";
import IconFlagAz from "../JSXIcons/flag/IconFlagAz";
import { dispatchCusEvent } from "../../../utils/utils";
import { ENUM_TAWK_API } from "../../../hooks/other/useTawkController";
import { useAppSelector } from "../../../store/index.ts";

export default function ChoseLang({ classNameUl = "" }) {
  const chooseLangRef = useRef(null);
  const dispatch = useDispatch();
  const langList = useAppSelector((state) => state.common.langList);
  const lang = useAppSelector((state) => state.common.lang);
  const isExtraSmall = useAppSelector((state) => state.deviceSize.isExtraSmall);
  const liveChatData = useAppSelector((state) => state.common.liveChatData);

  const getSelect = (lang) => (lang === lang ? "select" : "");

  const ImgByLang = {
    ru: <IconFlagRu />,
    en: <IconFlagEn />,
    uz: <IconFlagUz />,
    ar: <IconFlagAr />,
    az: <IconFlagAz />
  };

  const titleByLang = {
    ru: "Русский",
    en: "English",
    uz: "O'zbek",
    ar: "عربي",
    az: "Azərbaycan"
  };

  const changeLang = (lang) => {
    const propertyId = liveChatData[lang]?.propertyId;
    const widgetId = liveChatData[lang]?.widgetId;

    dispatch(setLang(lang));
    if (!propertyId) {
      dispatchCusEvent(ENUM_TAWK_API.hide);
      return;
    }
    dispatchCusEvent(ENUM_TAWK_API.switch, {
      detail: {
        propertyId,
        widgetId,
        cb: () => {
          dispatchCusEvent(ENUM_TAWK_API.show);
        }
      }
    });
  };

  return (
    <ShowContainer condition={Array.isArray(langList)}>
      <button
        className="chose-lang"
        onMouseOver={() => chooseLangRef.current.classList.remove("close")}>
        <span className="lang-item main select">
          {ImgByLang[lang]}

          <span className="text">
            {!isExtraSmall && titleByLang[lang]}
            <IconArrow className="chose-lang__arrow" />
          </span>
        </span>
        <ul ref={chooseLangRef} className={classNameUl}>
          {langList.map((lang, indx) => (
            <li
              key={indx}
              onClick={() => {
                changeLang(lang);
                chooseLangRef.current.classList.add("close");
              }}
              className={`lang-item ${getSelect(lang)}`}>
              <button>
                {ImgByLang[lang]}
                <span>{titleByLang[lang] || lang}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="chose-lang__plug" />
      </button>
    </ShowContainer>
  );
}
