import { useReadLocalStorage } from "usehooks-ts";
import { ENUM_LCS_NAME } from "../../store/enums";
import { useAppSelector } from "../../store/index.ts";

export default function useExternalLink() {
  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";
  const botName = useAppSelector((state) => state.common.botName);
  const supportChat = useAppSelector((state) => state.links.supportChat);
  const supportEmail = useAppSelector((state) => state.links.supportEmail);

  const playTg = start_r ? `https://t.me/${botName}?start_r=${start_r}` : `https://t.me/${botName}`;

  const supportTg = start_r
    ? `https://t.me/${supportChat}?start_r=${start_r}`
    : `https://t.me/${supportChat}`;

  return { playTg, supportTg, supportEmail };
}
