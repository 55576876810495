import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useEventListener } from "usehooks-ts";
import IconEye from "../JSXIcons/IconEye";
import IconEyeHide from "../JSXIcons/IconEyeHide";
import IconCopy from "../JSXIcons/IconCopy";
import { successToast } from "../Toaster";
import "./custom-input.scss";
import ShowContainer from "../ShowContainer";
import IconCross from "../JSXIcons/IconCross";

/**
 * @typedef {Object} CusInputProps
 * @property {string} [label] - Заголовок для input.
 * @property {string | React.ReactNode} [placeholder] - Плейсхолдер, может быть строкой или JSX.
 * @property {'text' | 'password' | 'email' | 'number' | 'tel' | 'url'} [type] - Тип input.
 * @property {string} [icon]                   - Иконка (путь к изображению).
 * @property {string} [grayWarning]            - Дополнительное предупреждение.
 * @property {number} [minLength]              - Минимальная длина.
 * @property {number} [maxLength]              - Максимальная длина.
 * @property {string} [dataType]               - Тип данных (например, email, password).
 * @property {boolean} [required]              - Поле обязательное.
 * @property {string} [name]                   - Имя поля.
 * @property {string} [defaultValue]           - Значение по умолчанию.
 * @property {React.ReactNode} [iconJSX]       - JSX-элемент для иконки.
 * @property {React.ReactNode} [iconJSXstart]  - JSX-элемент для иконки в начале.
 * @property {string} [iconSourceEnd]          - путь для картинки в конце
 * @property {function} [onChange]             - Обработчик изменения значения.
 * @property {function} [onBlur]               - Обработчик изменения значения.
 * @property {string} [errorWarning]           - Ошибка, которую нужно отобразить.
 * @property {string} [successInfo]            - Информация, которую нужно отобразить.
 * @property {boolean} [readOnly]              - Если true, поле только для чтения.
 * @property {boolean} [isCopy]                - Если true, показывать кнопку копирования.
 * @property {string} [plugText]               - Текст для отображения в поле.
 * @property {function} [onKeyDown]            - Обработчик для onKeyDown.
 * @property {function} [onKeyUp]              - Обработчик для onKeyUp.
 * @property {number} [min]                    - Минимальное значение (для числовых полей).
 * @property {number} [max]                    - Максимальное значение (для числовых полей).
 * @property {string} [sourceEndText]          - текст хинта для картинки в конце.
 * @property {string} [value]                  - значение инпута
 * @property {boolean} [clearBtn]              - флаг для очистки поля
 * @property {import("react").HTMLInputAutoCompleteAttribute} [autocomplete] - Значение для автозаполнения
 * @property { "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined } [inputMode] - Значение для автозаполнения
 */

/**
 * Компонент для ввода с кастомизацией.
 * @param {CusInputProps} props
 * @returns {JSX.Element}
 */
const CusInput = ({
  label,
  placeholder,
  type,
  icon,
  grayWarning,
  minLength,
  maxLength,
  dataType,
  required,
  name,
  defaultValue,
  iconJSX,
  iconJSXstart,
  iconSourceEnd,
  onChange,
  errorWarning,
  successInfo,
  readOnly,
  isCopy,
  plugText,
  onKeyDown,
  onKeyUp,
  min,
  max,
  autocomplete = "off",
  inputMode = undefined,
  onBlur,
  sourceEndText,
  clearBtn,
  value
}) => {
  const { t } = useTranslation();
  const [showPas, setShowPas] = useState(false);
  const [endSourceHint, setEndSourceHint] = useState(false);
  const [error, setError] = useState("");
  const typeInput = type === "password" && showPas ? "text" : type;
  const inputRef = useRef();

  useEventListener("inputError", (e) => {
    const detail = e.detail;
    if (detail.target === inputRef.current) setError(detail.message);
  });

  useEffect(() => {
    setError(errorWarning);
  }, [errorWarning]);

  const iconEndContainer = iconSourceEnd ? "icon-end-container" : "";
  const errorContainer = error ? "error-container" : "";
  const successInfoContainer = successInfo ? "success-container" : "";
  const iconStartContainer = iconJSXstart ? "icon-JSX-start" : "";

  const isCopyClass = isCopy ? "is-copy" : "";

  const containerClass = `custom-input ${iconEndContainer}  ${errorContainer} ${successInfoContainer} ${iconStartContainer}`;

  const inputContainerClass = `custom-input__container ${error ? "error" : ""} ${successInfo ? "success" : ""} ${plugText ? "plug-text-" + plugText.length : ""} `;

  const inputPlaceholder =
    typeof placeholder === "string" ? t(placeholder || label) : placeholder || label;

  const onInputChagne = (val) => {
    setError("");
    onChange?.(val);
  };

  return (
    <div className={containerClass}>
      <ShowContainer condition={label}>
        <span className="custom-input__title">
          {t(label)}
          {required && <span className="required"> *</span>}
        </span>
      </ShowContainer>
      <div className={inputContainerClass}>
        {!!icon && <img src={icon} alt="" />}
        <div className="icon-JSX-start__div">{!!iconJSXstart && iconJSXstart}</div>
        {plugText && <span className="plug-text">{plugText}</span>}
        <input
          value={value}
          defaultValue={defaultValue}
          ref={inputRef}
          readOnly={readOnly}
          onChange={onInputChagne}
          data-required={required}
          data-minlength={minLength}
          data-maxlength={maxLength}
          data-min={min}
          data-max={max}
          data-type={dataType}
          data-error={error}
          inputMode={inputMode}
          type={typeInput}
          name={name}
          autoComplete={autocomplete}
          placeholder={inputPlaceholder}
          className={`custom-input__input ${isCopyClass} ${type || ""} ${iconJSX ? "icon" : ""}`}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
        />

        <ShowContainer condition={iconJSX}>
          <button type="button" className="password__hide__btn">
            {iconJSX}
          </button>
        </ShowContainer>

        <ShowContainer condition={type === "password"}>
          <button
            type="button"
            className="password__hide__btn"
            onClick={(e) => {
              e.preventDefault();
              setShowPas((prev) => !prev);
            }}>
            {showPas ? <IconEye /> : <IconEyeHide />}
          </button>
        </ShowContainer>

        <ShowContainer condition={isCopy}>
          <button
            type="button"
            className="password__hide__btn"
            onClick={() => {
              navigator.clipboard.writeText(defaultValue);
              successToast(t("successfully copied to clipboard"));
            }}>
            <IconCopy />
          </button>
        </ShowContainer>

        <ShowContainer condition={iconSourceEnd}>
          <div
            className="source-end"
            onMouseEnter={() => setEndSourceHint(true)}
            onMouseLeave={() => setEndSourceHint(false)}>
            {endSourceHint && sourceEndText && <span className="hint">{t(sourceEndText)}</span>}
            <img src={iconSourceEnd} />
          </div>
        </ShowContainer>

        <ShowContainer condition={clearBtn}>
          <button
            type="reset"
            className="source-end clear-btn"
            onClick={() => {
              onInputChagne({ target: { value: "" } });
            }}>
            <IconCross />
          </button>
        </ShowContainer>
      </div>

      {!!error && <span className="text-error">{t(error)}</span>}
      {!!successInfo && <span className="text-success">{t(successInfo)}</span>}
      {grayWarning && <span className="custom-input__gray-warning">{t(grayWarning)}</span>}
    </div>
  );
};

export default CusInput;
