import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useCommonHooks from "./hooks/useCommonHooks";
import Main from "./pages/main";
import Game from "./pages/game";
import BonusGame from "./pages/bonusGame";
import Toaster from "./components/mui/Toaster";
import Games from "./pages/games";
import Terms from "./pages/terms";
import Head from "./Head";
import { useDispatch, useSelector } from "react-redux";
import { getParamFromUrl, setCookie } from "./utils/utils.js";
import { DEBUG } from "./store/enums";
import Banner from "./pages/banner.jsx";
import Promotions from "./pages/promotions.jsx";
import BottomNavigation from "./components/bottom_navigation/BottomNavigation.jsx";
import ConfirmEmail from "./pages/confirmEmail.jsx";
import NewPassword from "./pages/newPassword.jsx";
import FortuneModal from "./components/modal/fortune_modal/FortuneModal.jsx";
import { setModalFortune } from "./store/siteSlice.js";
import CommonModals from "./components/common_modals/CommonModals.jsx";
import { Suspense } from "react";
import LiveChat from "./components/LiveChat.jsx";

export default function App() {
  const sessionId = getParamFromUrl("sessionId");
  const isTgBot = useSelector((state) => state.common.isTgBot);

  if (sessionId && isTgBot) {
    if (DEBUG) console.log("document.cookie", document.cookie);
    if (DEBUG) console.log("set cookie", sessionId);
    setCookie("_GCS_SID_", sessionId);
    if (DEBUG) console.log("document.cookie", document.cookie);
  }

  return (
    <BrowserRouter>
      <NavigateOnLoad />

      <Routes>
        <Route path="/game/:id" element={<Game />} />
        <Route path="/games/:id/:subId" element={<Games />} />
        <Route path="/games/:id" element={<Games />} />
        <Route path="/bonus_game/:id" element={<BonusGame />} />
        <Route path="/banner/:id" element={<Banner />} />
        <Route path="/confirm_email/:code" element={<ConfirmEmail />} />
        <Route path="/new_password/:code" element={<NewPassword />} />
        <Route path="/promotions" element={<Promotions />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/*" element={<Main />} />
      </Routes>
      <BottomNavigation />
      <CommonModals />

      <Toaster />
    </BrowserRouter>
  );
}

function NavigateOnLoad() {
  useCommonHooks();
  const logoUrl = useSelector((state) => state.common.logoUrl);
  const logoText = useSelector((state) => state.common.casinoName);
  const openFortune = useSelector((state) => state.site.modals.fortune);

  const dispatch = useDispatch();

  const closeFortune = () => dispatch(setModalFortune(false));

  return (
    <>
      <Suspense>
        <Head title={logoText} favicon={logoUrl} appleTouchIcon={logoUrl} description={logoText} />
      </Suspense>
      <Suspense>
        <FortuneModal isOpen={!!openFortune} closeModal={closeFortune} />
      </Suspense>
      <Suspense>
        <LiveChat />
      </Suspense>
    </>
  );
}
