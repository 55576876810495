import React from "react";
import ShowContainer from "../../mui/ShowContainer";
import IconTg from "../../mui/JSXIcons/IconTg";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/index.ts";
import useExternalLink from "../../../hooks/common/useExternalLink.js";

export default function TgLinksFooter() {
  const { t } = useTranslation();
  const supportChat = useAppSelector((state) => state.links.supportChat);
  const botName = useAppSelector((state) => state.common.botName);
  const isTgBot = useAppSelector((state) => state.common.isTgBot);

  const { playTg, supportEmail, supportTg } = useExternalLink();

  return (
    <div className="footer__content__three__tg">
      <ShowContainer condition={!isTgBot}>
        <ShowContainer condition={botName}>
          <a className="footer__play-tg" target="_blank" href={playTg} rel="noreferrer">
            <span className="footer__play-tg__title">{t("Play as a bot:")}</span>
            <span className="footer__play-tg__name">
              <IconTg />@{botName}
            </span>
          </a>
        </ShowContainer>

        <ShowContainer condition={supportChat}>
          <a className="footer__support-tg" target="_blank" href={supportTg} rel="noreferrer">
            <span className="footer__support-tg__title">{t("Support: ")}</span>
            <span className="footer__support-tg__name">
              <IconTg />@{supportChat}
            </span>
          </a>
        </ShowContainer>
      </ShowContainer>

      <ShowContainer condition={supportEmail}>
        <a className="footer__support-tg" href={`mailto:${supportEmail}`} rel="noreferrer">
          <span className="footer__support-tg__title">{t("Support: ")}</span>
          <span className="footer__support-tg__name">@{supportEmail}</span>
        </a>
      </ShowContainer>
    </div>
  );
}
