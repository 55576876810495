import React from "react";
import "./banner-content.scss";
import Back from "../mui/back/Back";
import BannerItem from "../landing/banners/banner_item/BannerItem";
import useGetLink from "../../hooks/other/useGetLink";
import ShowContainer from "../mui/ShowContainer";

export default function BannerContent({ banner }) {
  const main = useGetLink("/");

  return (
    <section className="banner-page__content">
      <Back to={main} />
      <BannerItem {...banner} />

      <h2>{banner?.name}</h2>

      <ShowContainer condition={banner?.bannerText}>
        <div
          className="banner__full-text"
          dangerouslySetInnerHTML={{ __html: banner?.bannerText }}
        />
      </ShowContainer>
    </section>
  );
}
