import React, { useRef } from "react";
import "./carousel-desk.scss";
import ShowContainer from "../ShowContainer.jsx";
import ReactOwlCarousel from "react-owl-carousel";
import CarouselDeskHeader from "./carousel_desk_header/CarouselDeskHeader.jsx";
import useGetItems from "../../../hooks/other/useGetItems.js";

const AUTOPLAY_TIMEOUT = 5000;
const DELAY_STOP = 3000;

export default function CarouselDesk({
  id,
  isRow2,
  items = [],
  title,
  children,
  showAllLink = ""
}) {
  const sliderRef = useRef(null);
  const slidesToShow = useGetItems();
  const tmSlideStop = useRef();

  const manyLength = items.length > slidesToShow;

  const onDrag = (e) => {
    console.log("onDrag", e);

    clearTimeout(tmSlideStop.current);
    sliderRef.current.stop();
    tmSlideStop.current = setTimeout(() => sliderRef.current.play(), DELAY_STOP);
  };

  return (
    <ShowContainer condition={items.length}>
      <div className="carousel-desk">
        <CarouselDeskHeader
          title={title}
          showAllLink={showAllLink}
          sliderRef={sliderRef}
          manyLength={manyLength}
          onDrag={onDrag}
        />
        {manyLength ? (
          <ReactOwlCarousel
            ref={sliderRef}
            startPosition={0}
            className="carousel-desk__items"
            margin={10}
            loop={false}
            rewind={true}
            autoplay={true}
            autoplayTimeout={AUTOPLAY_TIMEOUT}
            responsiveRefreshRate={0}
            mouseDrag={true}
            dots={false}
            slideBy={slidesToShow}
            autoplayHoverPause={true}
            items={slidesToShow}
            onDrag={onDrag}>
            {React.Children.map(children, (child, indx) => {
              if (isRow2) {
                return children;
              }

              return (
                <div
                  key={indx}
                  id={`${id}-item-${indx}`}
                  className="carousel-item"
                  data-index={indx}>
                  {child}
                </div>
              );
            })}
          </ReactOwlCarousel>
        ) : (
          <div className={`carousel-desk__items carousel-plug carousel-plug-${slidesToShow}`}>
            {children}
          </div>
        )}
      </div>
    </ShowContainer>
  );
}
