import React from "react";
import DefaultModal from "../../DefaultModal";
import { useTranslation } from "react-i18next";
import imgBonusAZN from "./img/bonus-AZN.png";
import imgBonusEURO from "./img/bonus-EURO.png";
import imgBonusRUB from "./img/bonus-RUB.png";
import imgBonusUSDT from "./img/bonus-USDT.png";
import imgBonusUZS from "./img/bonus-UZS.png";
import "./register-fortune.scss";
import { useReadLocalStorage } from "usehooks-ts";
import { ENUM_CURRENCY, ENUM_LCS_NAME, ENUM_LOGIN } from "../../../../store/enums";
import LoginFields from "../../login_modal/LoginFields";
import ChooseCurrency from "../../../mui/choose_currency/ChooseCurrency";
import { setModalBalance } from "../../../../store/siteSlice";
import { useDispatch } from "react-redux";

export default function RegisterFortune({ isOpen, closeModal, currency, setCurrency, hideClose }) {
  const { t } = useTranslation();
  const alreadyAccount = useReadLocalStorage(ENUM_LCS_NAME.alreadyAccount);
  const dispatch = useDispatch();

  const bonusByCurrency = {
    [ENUM_CURRENCY.AZN]: imgBonusAZN,
    [ENUM_CURRENCY.EUR]: imgBonusEURO,
    [ENUM_CURRENCY.RUB]: imgBonusRUB,
    [ENUM_CURRENCY.USDT]: imgBonusUSDT,
    [ENUM_CURRENCY.UZS]: imgBonusUZS
  };

  const imgBonus = bonusByCurrency[currency];

  return (
    <DefaultModal
      classModal={`fortune-register`}
      isOpen={isOpen}
      hideClose={hideClose}
      miniModal
      closeModal={() => {
        if (hideClose) return;
        closeModal();
      }}>
      <div className="fortune-register__header">
        <h1>{t("You won")}</h1>

        <div className="fortune-register__currency">
          <ChooseCurrency
            onChange={(e) => setCurrency(e)}
            myCurrency={currency}
            setCurrency={setCurrency}
          />
        </div>
      </div>
      <img className="fortune-register__bonus" src={imgBonus} />

      <LoginFields
        isSwitch={alreadyAccount}
        dataLogin={ENUM_LOGIN.register}
        closeModal={closeModal}
        h3Text={"Сreate your account and get a bonus"}
        onSuccessLogin={() => {
          dispatch(setModalBalance(true));
          // dispatch(setModalDepostiBonus(true));
        }}
      />
    </DefaultModal>
  );
}
