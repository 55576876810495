import React, { lazy, Suspense } from "react";
import "./terms.scss";
import Header from "../components/landing/header/Header";
import Footer from "../components/landing/footer/Footer";
import { useSelector } from "react-redux";
import useScrollUp from "../hooks/useScrollUp";
import ButtonUp from "../components/mui/button_up/ButtonUp";
import PageWitchMenu from "../components/mui/page_witch_menu/PageWitchMenu";

const TermsEn = lazy(() => import("../components/terms/TermsEn"));
const TermsRu = lazy(() => import("../components/terms/TermsRu"));

export default function Terms() {
  useScrollUp();
  const lang = useSelector((state) => state.common.lang);

  return (
    <PageWitchMenu className={"terms"}>
      <Header />
      <div className="terms__content">
        {lang === "ru" ? (
          <Suspense>
            <TermsRu />
          </Suspense>
        ) : (
          <Suspense>
            <TermsEn />
          </Suspense>
        )}
      </div>{" "}
      <ButtonUp />
      <Footer />
    </PageWitchMenu>
  );
}
