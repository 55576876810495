import React, { useEffect } from "react";
import "./top-up-balance-bonus.scss";
import { useAppSelector } from "../../../../store/index.ts";
import ShowContainer from "../../../mui/ShowContainer.jsx";
import { useReadLocalStorage } from "usehooks-ts";
import { ENUM_LCS_NAME } from "../../../../store/enums.js";
import BonusClassic from "./BonusClassic.jsx";
import BonusFortune from "./BonusFortune.jsx";
import { dispatchCusEvent } from "../../../../utils/utils.js";
import { ENUM_COMMON_EVENT } from "../../../../hooks/common/useListener.js";

export default function TopUpBonus() {
  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";
  const isFortune = useReadLocalStorage(ENUM_LCS_NAME.isFortune);

  const userId = useAppSelector((state) => state.user.userId);
  const firstDep = useAppSelector((state) => state.promotions.firstDeposit);
  const cashback = useAppSelector((state) => state.promotions.cashback);
  const forUserBonus = useAppSelector((state) => state.promotions.forUser);

  const bonusCashback = cashback?.values?.percent;
  const bonusWager = firstDep?.values?.wager;

  const isBonus = bonusCashback || bonusWager;
  const bonusForMe = userId === forUserBonus;

  useEffect(() => {
    if (bonusForMe) return;

    dispatchCusEvent(ENUM_COMMON_EVENT.getBonus);
  }, [userId, forUserBonus, start_r]);

  return (
    <ShowContainer condition={bonusForMe && isBonus}>
      {isFortune ? <BonusFortune /> : <BonusClassic />}
    </ShowContainer>
  );
}
