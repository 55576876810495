import React, { useState } from "react";
import "./avatar-popup.scss";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../store/index.ts";
import IconArrow from "../../../../mui/JSXIcons/IconArrow.jsx";
import { useTranslation } from "react-i18next";
import ShowContainer from "../../../../mui/ShowContainer.jsx";
import { setModalLogin, setModalProfile } from "../../../../../store/siteSlice.js";
import { dispatchCusEvent } from "../../../../../utils/utils.js";
import { ENUM_COMMON_EVENT } from "../../../../../hooks/common/useListener.js";
import { ENUM_ACCOUNT_REGISTER, ENUM_LCS_NAME } from "../../../../../store/enums.js";
import AvatarImg from "./AvatarImg.jsx";
import useNickname from "../../../../../hooks/useNickname.js";
import { useReadLocalStorage } from "usehooks-ts";

export default function AvatarPopup() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const nickname = useNickname();
  const alreadyAccount = useReadLocalStorage(ENUM_LCS_NAME.alreadyAccount);
  const isSmallDesktop = useAppSelector((state) => state.deviceSize.isSmallDesktop);
  const registerType = useAppSelector((state) => state.user.info.regType);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const isRegisterEmail = registerType === ENUM_ACCOUNT_REGISTER.email;

  const [openPopup, setOpenPopup] = useState(false);

  const arrowClass = openPopup ? "rotate180" : "";
  const classPopup = isSmallDesktop ? "small-desktop" : "";
  const classAuth = isAuth ? "is-auth" : "";
  const textLoginBtn = alreadyAccount ? "Login" : "Sign up";

  const onLoginClick = () => {
    // dispatch(setTypeLogin(ENUM_LOGIN.register));
    dispatch(setModalLogin(true));
  };

  return (
    <div className={`avatar-popup ${classPopup} ${classAuth}`}>
      {isAuth ? (
        <button className="avatar-popup__person" onClick={() => setOpenPopup((prev) => !prev)}>
          <AvatarImg />
          <ShowContainer condition={!isSmallDesktop}>
            <span className="avatar-popup__person__name">{nickname}</span>
            <IconArrow className={arrowClass} />
          </ShowContainer>
        </button>
      ) : (
        <button className="avatar-popup__person" onClick={onLoginClick}>
          <span className="avatar-popup__person__auth">{t(textLoginBtn)}</span>
        </button>
      )}

      <ShowContainer condition={openPopup && isAuth}>
        <ul className="avatar-popup__popup">
          <ShowContainer condition={isSmallDesktop}>
            <li className="nickname">
              <span className="avatar-popup__person__name">{nickname}</span>
            </li>
          </ShowContainer>
          <ShowContainer condition={isRegisterEmail}>
            <li>
              <button onClick={() => dispatch(setModalProfile(true))}>{t("Profile")}</button>
            </li>
          </ShowContainer>
          <li>
            <button onClick={() => dispatchCusEvent(ENUM_COMMON_EVENT.exit)}>{t("Exit")}</button>
          </li>
        </ul>
      </ShowContainer>
    </div>
  );
}
