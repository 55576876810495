import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ShowContainer from "../ShowContainer";
import IconArrow from "../JSXIcons/IconArrow";
import "../chose_lang/chose-lang.scss";
import { setCurrency } from "../../../store/userSlice";
import { API_USER } from "../../../api/user";
import { TEST_MODE } from "../../../store/enums";
import "./choose-currency.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/index.ts";

/**
 *
 * @param {Object} props
 * @param {boolean} props.onChange // локальный вариант использования компонента без запроса на сервер
 * @param {string} props.classNameUl
 * @param {string} props.myCurrency  // вывод конкретный валюты
 * @param {React.Component} props.FullReplenishRight  // компонент FullReplenishRight
 * @param {React.Component} props.FullControls  // компонент FullControls
 */
export default function ChooseCurrency({
  classNameUl = "",
  myCurrency,
  onChange,
  FullReplenishRight,
  FullControls
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currencyDefaultSite = useAppSelector((state) => state.common.currencyDefault);
  const currencySite = useAppSelector((state) => state.user.currency);
  const balance = useAppSelector((state) => state.user.balance);
  const currencies = useAppSelector((state) => state.common.currencies) || {};
  const isMobile = useAppSelector((state) => state.deviceSize.isMobile);
  const isAuth = useAppSelector((state) => state.user.isAuth);

  const [isOpen, setOpen] = useState(false);

  const currency = myCurrency || currencySite || currencyDefaultSite;
  const currenciesName = Object.keys(currencies);
  const openClass = isOpen ? "open" : "";

  useEffect(() => {
    if (!isAuth) return;

    API_USER.changeCurrency({ currency })
      .then((e) => {
        console.log("res change cur", e);
        const data = e.data;
        dispatch(setCurrency(data.currency));
      })
      .catch(console.error);
  }, [currency]);

  const getMainBalance = (currency) => {
    const mainBalance = balance[currency]?.balances?.main;
    const decimal = balance[currency]?.info?.decimal || 0;
    return mainBalance?.toFixed(decimal) || 0;
  };

  const getBonusBalance = (currency) => {
    const bonusBalance = balance[currency]?.balances?.bonus;
    const decimal = balance[currency]?.info?.decimal || 0;

    return bonusBalance?.toFixed(decimal) || 0;
  };

  const getSelect = (val) => (val === currency ? "active" : "");

  const changeCurrency = (currency) => {
    if (TEST_MODE) {
      console.log(currency);
      dispatch(setCurrency(currency));
      return;
    }

    if (onChange) {
      onChange(currency);
      return;
    }

    dispatch(setCurrency(currency));
  };

  const bonusBalance = +getBonusBalance(currency);

  const onCurrencyContainerCick = () => {
    if (!isMobile) return;
    setOpen((prev) => !prev);
  };

  return (
    <ShowContainer condition={currenciesName?.length}>
      <div
        className={`choose-currency-container ${openClass}`}
        onMouseOver={() => (isMobile ? undefined : setOpen(true))}
        onMouseLeave={() => setOpen(false)}>
        <div className="choose-currency__wrapper">
          <span className="choose-currency__gradient" />
          <button className="choose-currency" onClick={onCurrencyContainerCick}>
            <span className="choose-currency__active">
              <span className="balance-value">
                <span className="main">{getMainBalance(currency)}</span>
                {!!bonusBalance && <span className="bonus">{bonusBalance}</span>}
              </span>
              <span className="currency">{currency}</span>
              <IconArrow className="choose-currency__arrow" />
            </span>
          </button>
        </div>

        <div className="choose-currency__plug">
          <ul className={classNameUl}>
            {currenciesName.map((el, indx) => {
              const bonusBalance = getBonusBalance(el);

              return (
                <li
                  key={indx}
                  onClick={() => {
                    changeCurrency(el);
                    setOpen(false);
                  }}>
                  <button className={`currency-item ${getSelect(el)}`}>
                    <div className="main">
                      <span className="balance-value">
                        <span className="main">{getMainBalance(el)}</span>
                      </span>
                      <span className="currency">{el}</span>
                    </div>
                    <ShowContainer condition={!!+bonusBalance}>
                      <div className="bonus">
                        <span className="bonus">
                          {t("bonus: ")} {bonusBalance}
                        </span>
                      </div>
                    </ShowContainer>
                  </button>
                </li>
              );
            })}

            <ShowContainer condition={FullControls}>{<FullControls />}</ShowContainer>
          </ul>
        </div>

        <ShowContainer condition={FullReplenishRight}>
          <FullReplenishRight />
        </ShowContainer>
      </div>
    </ShowContainer>
  );
}
