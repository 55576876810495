import React from "react";
import "./header-auth.scss";
import Online from "../online/Online";
import HeaderAuthMenu from "./HeaderAuthMenu";

export default function HeaderAuth() {
  return (
    <div className="header__auth">
      <Online />
      <HeaderAuthMenu />
    </div>
  );
}
