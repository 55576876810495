import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";
import { setModalBalance, setTypeLogin } from "../../../store/siteSlice";
import { DEBUG, ENUM_ACCOUNT_REGISTER, ENUM_LCS_NAME, ENUM_LOGIN } from "../../../store/enums";
import { API_USER } from "../../../api/user";
import { errorToast, successToast } from "../../mui/Toaster";
import { setInfo, setUserId } from "../../../store/userSlice";
import ShowContainer from "../../mui/ShowContainer";
import AuthTg from "../../mui/auth_tg/AuthTg";
import CusForm from "../../mui/CusForm";
import CusInput from "../../mui/custom_input/CusInput";
import CusButton from "../../mui/custom_button/CusButton";
import CheckboxInfo from "../../mui/checkbox_info/CheckboxInfo";
import { Link } from "react-router-dom";
import IconLock from "../../mui/JSXIcons/IconLook";
import { dispatchCusEvent, messageByCode } from "../../../utils/utils";
import { ENUM_COMMON_EVENT } from "../../../hooks/common/useListener";
import { ENUM_HASH } from "../../../hooks/other/useUrlParc";
import { useLocation } from "react-router-dom";
import EmailInput from "../../mui/EmailInput";
import CusCaptcha from "../../CusCaptcha";
import CusSegment from "../../mui/cus_segment/CusSegment";
import { useAppSelector } from "../../../store/index.ts";
import { API_CASINO } from "../../../api/casino.js";

export default function LoginFields({
  closeModal,
  dataLogin,
  setForgotPas,
  isSwitch = true,
  myBlock,
  onSuccessLogin,
  h3Text
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const hash = location.hash;

  const start_r = useReadLocalStorage(ENUM_LCS_NAME.startR) || "";
  const pbParams = useReadLocalStorage(ENUM_LCS_NAME.pbParams) || "";
  const [, setAlreadyAccount] = useLocalStorage(ENUM_LCS_NAME.alreadyAccount);

  const typeLogin =
    useAppSelector((state) => state.site.modals?.data?.login?.type) || ENUM_LOGIN.register;
  const botName = useAppSelector((state) => state.common.botName);
  const telegramWidget = useAppSelector((state) => state.common.telegramWidget);
  const casinoName = useAppSelector((state) => state.common.casinoName);
  const currency = useAppSelector((state) => state.user.currency);

  const [disableBtn, setDisableBtn] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const captchaRef = useRef();

  const isLogin = typeLogin == ENUM_LOGIN.login;

  const changeTypeLogin = (type) => dispatch(setTypeLogin(type));

  const typeOptions = [
    { label: t("Login"), value: ENUM_LOGIN.login },
    { label: t("Registration"), value: ENUM_LOGIN.register }
  ];

  const titleByType = {
    [ENUM_LOGIN.login]: t("Login"),
    [ENUM_LOGIN.register]: t("Register")
  };

  const subTitleByType = {
    [ENUM_LOGIN.login]: t("Login to your account via Telegram or Email"),
    [ENUM_LOGIN.register]: t("Create an account in casino", { casino: casinoName })
  };

  const btnTextByType = {
    [ENUM_LOGIN.login]: t("Login by email"),
    [ENUM_LOGIN.register]: t("Registration by email")
  };

  const tgTitleByType = {
    [ENUM_LOGIN.login]: t("Login via Telegram"),
    [ENUM_LOGIN.register]: t("Registration via Telegram")
  };
  const emailTitleByType = {
    [ENUM_LOGIN.login]: t("Login via Email"),
    [ENUM_LOGIN.register]: t("Registration via Email")
  };

  const checkDepositOn = () => {
    if (hash.includes(ENUM_HASH.signup_deposit_on)) dispatch(setModalBalance(true));
  };

  const onComeIn = async (e, field) => {
    e.preventDefault();
    setBtnLoading(true);

    const info = {
      email: field.email,
      password: field.password,
      captcha: captchaRef.current?.getValue?.(),
      currency: currency
    };

    if (typeLogin === ENUM_LOGIN.register) {
      const domain = window.location.hostname;

      info.domain = domain;
      info.start_r = start_r;
      info.pb_params = pbParams;
    }

    setDisableBtn(true);
    await API_USER.login
      .email(info)
      .then((e) => {
        const data = e.data;
        const error = data.dataError || data.error;
        if (DEBUG) console.log("loginEmail res", e);

        if (error) {
          captchaRef.current?.reset();
          errorToast(data.dataError ? t(messageByCode(data.dataError)) : data.error);
          return;
        }

        dispatch(setUserId(data.user_id));
        successToast(t("Successfully"));

        closeModal(true);
        dataLogin?.cb?.();
        onSuccessLogin?.();

        setTimeout(() => {
          dispatchCusEvent(ENUM_COMMON_EVENT.getBalance);
        }, 100);

        dispatch(
          setInfo({
            nickname: field.email,
            email: field.email,
            register: ENUM_ACCOUNT_REGISTER.email
          })
        );
        setAlreadyAccount(true);

        if (typeLogin === ENUM_LOGIN.register) {
          dispatch(setModalBalance(true));
        }

        checkDepositOn();
      })
      .catch((e) => {
        if (DEBUG) console.log("catch loginEmail", e);
        errorToast(e || "unknown error");
      });
    captchaRef.current?.reset();
    setDisableBtn(false);
    setBtnLoading(false);
  };

  useEffect(() => {
    captchaRef.current?.reset();
  }, []);

  useEffect(() => {
    API_CASINO.captchaUsing({ start_r })
      .then((e) => {
        if (isLogin) {
          setShowCaptcha(true);
        } else {
          setShowCaptcha(e.data?.data?.reg ?? true);
        }
      })
      .catch(() => {
        setShowCaptcha(true);
      });
  }, [isLogin]);

  return (
    <div className="login-fields">
      <h2>{titleByType[typeLogin]}</h2>
      {h3Text ? t(h3Text) : <h3>{subTitleByType[typeLogin]}</h3>}

      <ShowContainer condition={isSwitch}>
        <CusSegment
          className="login__segment custom-segment"
          value={typeLogin}
          options={typeOptions}
          onChange={changeTypeLogin}
        />
      </ShowContainer>

      <ShowContainer condition={telegramWidget && botName}>
        <span className="tg__title">
          <div className="tg__title__left" />
          <span className="tg__title__text">{tgTitleByType[typeLogin]}</span>
          <div className="tg__title__right" />
        </span>
        <div className="login__auth login__auth-tg">
          <AuthTg
            successCb={() => {
              dataLogin?.cb?.();
              checkDepositOn();
            }}
            closeModal={closeModal}
          />
        </div>
      </ShowContainer>

      <CusForm onSubmit={onComeIn} className={"login__form"}>
        <span className="tg__title">
          <div className="tg__title__left" />
          <span className="tg__title__text">{emailTitleByType[typeLogin]}</span>
          <div className="tg__title__right" />
        </span>

        <EmailInput />

        <CusInput
          name={"password"}
          placeholder={"Password"}
          type={"password"}
          autocomplete="current-password"
          required
          minLength={8}
          iconJSXstart={<IconLock />}
        />
        <ShowContainer condition={showCaptcha}>
          <CusCaptcha captchaRef={captchaRef} />
        </ShowContainer>

        <ShowContainer condition={myBlock}>{myBlock}</ShowContainer>

        <CusButton
          className="login-submit"
          text={btnTextByType[typeLogin]}
          type={"submit"}
          disable={disableBtn}
          color="gradient-blue"
          btnLoading={btnLoading}
          setBtnLoading={setBtnLoading}
        />
        <ShowContainer condition={!isLogin}>
          <CheckboxInfo
            defaultChecked
            id={"login-agree"}
            text={
              <>
                <span>{t("I agree to the")}</span>{" "}
                <Link target="_blank" to={"/terms"}>
                  {t("User Agreement")}
                </Link>{" "}
                <span>{t("and confirm that I am over 18 years old.")}</span>
              </>
            }
          />
        </ShowContainer>
      </CusForm>
      <ShowContainer condition={isLogin && setForgotPas}>
        <button className="forgot-pas" onClick={() => setForgotPas(true)}>
          {t("Forgot your password?")}
        </button>
      </ShowContainer>
    </div>
  );
}
