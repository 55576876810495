import React, { lazy, Suspense, useEffect, useState } from "react";
import DefaultModal from "../DefaultModal";
import { useAppSelector } from "../../../store/index.ts";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import { ENUM_LCS_NAME } from "../../../store/enums.js";
import "./fortune-modal.scss";
import FortuneWheel from "./fortune_wheel/FortuneWheel.jsx";
import FortuneBonus from "./your_bonus/FortuneBonus.jsx";
import RegisterFortune from "./register_fortune/RegisterFortune.jsx";
import { dispatchCusEvent } from "../../../utils/utils.js";
import { ENUM_TAWK_API } from "../../../hooks/other/useTawkController.js";

const FortuneZeus = lazy(() => import("./zeus/FortuneZeus.jsx"));
const ChooseCurrency = lazy(() => import("../../mui/choose_currency/ChooseCurrency.jsx"));
const ChoseLang = lazy(() => import("../../mui/chose_lang/ChoseLang.jsx"));
const Logo = lazy(() => import("../../landing/header/logo/Logo.jsx"));
const JewelryStone = lazy(() => import("./jewelry-stone/JewelryStone.jsx"));

//FIXME проверить после передачи валюты в авторизацию
export default function FortuneModal({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const [isFortune, setFortune] = useLocalStorage(ENUM_LCS_NAME.isFortune);

  const fortuneLock = useAppSelector((state) => state.site.lock.fortuneClose);
  const currencyDefault = useAppSelector((state) => state.common.currencyDefault);

  const [goZeus, setGoZeus] = useState(false);
  const [showBonus, setShowBonus] = useState(false);
  const [isRegister, setRegister] = useState(false);
  const [currency, setCurrency] = useState(currencyDefault);

  useEffect(() => {
    if (currency) return;
    setCurrency(currencyDefault);
  }, [currencyDefault, currency]);

  const onWheelClick = () => setGoZeus(true);

  const onShowBonus = () => {
    setShowBonus(true);
    setGoZeus(false);
    setTimeout(() => {
      setRegister(true);
      setFortune(true);
    }, 2000);
  };

  const checkTawn = (isShow) => {
    isShow ? dispatchCusEvent(ENUM_TAWK_API.hide) : dispatchCusEvent(ENUM_TAWK_API.show);
  };

  useEffect(() => {
    if (isFortune && isOpen) setRegister(true);
    checkTawn(isOpen);

    return checkTawn(isOpen);
  }, [isFortune, isOpen]);

  const changeCurrency = (currency) => {
    setCurrency(currency);
  };

  return (
    <>
      <DefaultModal
        classModal={`fortune-modal `}
        isOpen={isOpen}
        closeModal={closeModal}
        hideClose={fortuneLock}
        isFull>
        <section className="container">
          <header>
            <Suspense>
              <div className="fortune__logo">
                <Logo />
              </div>
            </Suspense>
            <div className="fortune__title">
              <h2>{t("Spin the wheel")}</h2>
              <h3>{t("Get bonuses")}</h3>
            </div>
            <Suspense>
              <div className="fortune__currency">
                <ChooseCurrency
                  classNameUl="fortune__currency_ul"
                  myCurrency={currency}
                  onChange={changeCurrency}
                />
              </div>
            </Suspense>
          </header>
          <main>
            <Suspense>
              <FortuneZeus goZeus={goZeus} />
            </Suspense>
            <FortuneWheel currency={currency} onClick={onWheelClick} onBonus={onShowBonus} />
            <FortuneBonus currency={currency} showBonus={showBonus} />

            <Suspense>
              <JewelryStone />
            </Suspense>
          </main>
          <footer>
            <Suspense>
              <ChoseLang />
            </Suspense>
          </footer>
        </section>
      </DefaultModal>
      <RegisterFortune
        isOpen={isRegister}
        currency={currency}
        hideClose={fortuneLock}
        setCurrency={setCurrency}
        closeModal={() => {
          setRegister(false);
          closeModal();
        }}
      />
    </>
  );
}
