import React from "react";
import CusButton from "../custom_button/CusButton";
import IconCirclePlus from "../JSXIcons/IconCirclePlus";
import useLoginAction from "../../../hooks/useLoginAction";
import { useDispatch } from "react-redux";
import { setModalBalance, setPromoCode, setWithdrawal } from "../../../store/siteSlice";
import IconCircleMinus from "../JSXIcons/IconCircleMinus";
import ChooseCurrency from "./ChooseCurrency";

export default function ChooseCurrencyFull({ classNameUl = "", myCurrency, onChange }) {
  const { onLoginAction } = useLoginAction();
  const dispatch = useDispatch();

  const FullControls = () => (
    <>
      <li className="controls-btn">
        <CusButton
          color="gradient-blue"
          icon={<IconCirclePlus />}
          className="plus"
          border
          onClick={() => onLoginAction(() => dispatch(setModalBalance(true)))}
        />
        <CusButton
          icon={<IconCircleMinus />}
          className="minus"
          border
          onClick={() => onLoginAction(() => dispatch(setWithdrawal(true)))}
        />
      </li>
      <li className="controls-promo">
        <CusButton
          text={"Promo codes"}
          onClick={() => onLoginAction(() => dispatch(setPromoCode(true)))}
        />
      </li>
    </>
  );

  const FullReplenishRight = () => (
    <CusButton
      color="gradient-blue"
      icon={<IconCirclePlus />}
      className="replenish full-replenish-right"
      border
      text="Replenish"
      onClick={() => onLoginAction(() => dispatch(setModalBalance(true)))}
    />
  );

  return (
    <ChooseCurrency
      onChange={onChange}
      myCurrency={myCurrency}
      classNameUl={classNameUl}
      FullControls={FullControls}
      FullReplenishRight={FullReplenishRight}
    />
  );
}
