import { useDispatch, useSelector } from "react-redux";
import { useEventListener } from "usehooks-ts";
import { API_USER } from "../../api/user";
import { setBalance, setCurrency, setUserId } from "../../store/userSlice";
import { DEBUG, TEST_MODE } from "../../store/enums";
import { dispatchCusEvent } from "../../utils/utils";
import { addFavoriteGames, removeFavoriteGames } from "../../store/gamesSlice";

export const ENUM_COMMON_EVENT = {
  sessionError: "session.error",
  exit: "account.exit",
  favoritesClick: "favorites-click",
  checkOpenModal: "check.open.modal",
  getBalance: "site.balance.get",
  getInfo: "site.info.get",
  getBonus: "site.bonus.get"
};

export default function useListener() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.user.isAuth);
  const gamesById = useSelector((state) => state.games.gamesById);

  const eventSessionError = ENUM_COMMON_EVENT.sessionError;
  const eventExit = ENUM_COMMON_EVENT.exit;
  const eventBalance = ENUM_COMMON_EVENT.getBalance;
  const favoritesClick = ENUM_COMMON_EVENT.favoritesClick;

  const onExit = async () => {
    await API_USER.logout();
    dispatch(setUserId(0));
    dispatch(setBalance({}));
  };

  const getBalance = (DEBUG = false) => {
    if (!isAuth) return;
    if (TEST_MODE) return;

    return API_USER.balance
      .info()
      .then((e) => {
        const data = e.data;

        if (data.errorCode == 1 && data.session === false) {
          console.log("error");
          dispatchCusEvent(ENUM_COMMON_EVENT.exit);
        }

        if (DEBUG) console.log("balance res", e);
        dispatch(setBalance(data));
        for (const currency in data) {
          const info = data[currency]?.info;
          if (info?.current) {
            dispatch(setCurrency(currency));
            break;
          }
        }
      })
      .catch((e) => {
        if (DEBUG) console.log("balance catch", e);
      });
  };

  useEventListener(favoritesClick, (e) => {
    const elem = e.detail.elem;

    const id = elem.id;
    const currentGame = gamesById[id];
    if (!currentGame) return;

    API_USER.toggleFavorites(id)
      .then((e) => {
        if (DEBUG) console.log("favorites res", e);
        const isFavorites = e.data.favorites;
        isFavorites
          ? dispatch(addFavoriteGames(currentGame))
          : dispatch(removeFavoriteGames(currentGame));
      })
      .catch((e) => {
        if (DEBUG) console.log("catch", e);
      });
  });

  useEventListener(eventSessionError, () => {
    dispatchCusEvent(ENUM_COMMON_EVENT.checkOpenModal);
  });

  useEventListener(eventExit, () => {
    onExit();
  });

  useEventListener(eventBalance, () => {
    getBalance();
  });
}
