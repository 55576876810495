import React from "react";
import IconCross from "../JSXIcons/IconCross";
import "./close-modal.scss";
import { useTranslation } from "react-i18next";

export default function CloseModal({ close }) {
  const { t } = useTranslation();
  return (
    <button className="modal__close" title={t("Close")} onClick={close}>
      <IconCross />
    </button>
  );
}
