import React, { lazy } from "react";
import { useSelector } from "react-redux";

import "./header.scss";
import ShowContainer from "../../mui/ShowContainer";
import ErrorBoundary from "../../mui/error_boundary/ErrorBoundary";
import useNavigateWithSearch from "../../../hooks/useNavigateWitchSearch";
import CusSuspence from "../../mui/CusSuspence";
import HeaderNav from "./nav/HeaderNav";
import HeaderAuth from "./auth/HeaderAuth";

const AsideMenu = lazy(() => import("../menu/AsideMenu"));

export default function Header() {
  const isAuth = useSelector((state) => state.user.isAuth);
  const isMenuAbsolute = useSelector((state) => state.deviceSize.isMenuAbsolute);
  const isLsm = useSelector((state) => state.deviceSize.isLsm);
  const logoText = useSelector((state) => state.common.casinoName);
  const isTgBot = useSelector((state) => state.common.isTgBot);
  const isExtraSmall = useSelector((state) => state.deviceSize.isExtraSmall);
  const headerClass = isAuth ? "auth" : "no-auth";

  const navigate = useNavigateWithSearch();

  const onTextClick = () => {
    navigate("/");
  };

  return (
    <>
      <header className={`header ${headerClass}`}>
        <ShowContainer condition={isMenuAbsolute}>
          <CusSuspence>
            <AsideMenu showLogo={isTgBot || !isExtraSmall} />
          </CusSuspence>
        </ShowContainer>
        <ShowContainer condition={!isLsm}>
          <ErrorBoundary>
            <HeaderNav />
          </ErrorBoundary>
        </ShowContainer>

        {isTgBot ? (
          <ShowContainer condition={isExtraSmall}>
            <span className="header__logo__text" onClick={onTextClick}>
              {logoText}
            </span>
          </ShowContainer>
        ) : (
          <ErrorBoundary>
            <HeaderAuth />
          </ErrorBoundary>
        )}
      </header>
      <ShowContainer condition={isLsm}>
        <div className="header-nav-two">
          <CusSuspence>
            <HeaderNav />
          </CusSuspence>
        </div>
      </ShowContainer>
    </>
  );
}
