import React, { Suspense } from "react";
import ErrorBoundary from "./error_boundary/ErrorBoundary";

export default function CusSuspence({ children, fallback }) {
  return (
    <ErrorBoundary>
      <Suspense fallback={fallback}>{children}</Suspense>
    </ErrorBoundary>
  );
}
