import React, { useEffect, useMemo, useState } from "react";
import LoaderContainer from "../../mui/loader_container/LoaderContainer";
import ErrorBoundary from "../../mui/error_boundary/ErrorBoundary";
import TopUpBonus from "./bonus/TopUpBonus";
import TopUpCrypto from "./crypto/TopUpCrypto";
import TopUpReal from "./real/TopUpReal";
import TopUpSum from "./summ/TopUpSum";
import TopUpCards from "./cards/TopUpCards";
import { API_USER } from "../../../api/user";
import { ENUM_COMMON_EVENT } from "../../../hooks/common/useListener";
import { dispatchCusEvent } from "../../../utils/utils";
import { DEBUG, ENUM_BALANCE_TYPE, TEST_MODE } from "../../../store/enums";
import { useAppSelector } from "../../../store/index.ts";
import { useDispatch } from "react-redux";
import { errorToast } from "../../mui/Toaster";
import { useTranslation } from "react-i18next";
import { testDeposit } from "../../../store/testConst";
import { setCurrency } from "../../../store/userSlice";
import { setLockBalanceClose } from "../../../store/siteSlice";

export default function StepContainer({
  closeModal,
  isOpen,
  setLockedInfo,
  lockedInfo,
  setOpenPaymentPhone,
  setOpenPaymentCard
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const balance = useAppSelector((state) => state.user.balance);
  const currency = useAppSelector((state) => state.user.currency);
  const lockBonusClose = useAppSelector((state) => state.site.lock.balanceClose);

  const type = useMemo(() => balance?.[currency]?.info?.type, [currency, balance]);
  const isCrypto = useMemo(() => type === ENUM_BALANCE_TYPE.crypto, [type]);

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({});
  const [step, setStep] = useState(1);
  const [min, setMin] = useState(undefined);
  const [max, setMax] = useState(undefined);

  const checkOpen = (data) => {
    if (!isOpen) return;
    const sessionError = ENUM_COMMON_EVENT.sessionError;

    if (data.errorCode === 1) dispatchCusEvent(sessionError);
  };

  useEffect(() => {
    let countLoader = 0;

    const checkLoader = () => {
      countLoader++;
      if (countLoader == 2) setLoader(false);
    };

    setStep(1);

    if (!isOpen) return;
    if (!currency) return;
    if (TEST_MODE) {
      const data = testDeposit;
      dispatch(setCurrency(data.currency));
      setMin(data.minAmount);
      setMax(data.maxAmount);
      setLoader(false);
      return;
    }

    API_USER.balance
      .getDepositLockInfo()
      .then((e) => {
        if (DEBUG) console.log("limit deposit res", e);
        const data = e.data;
        if (!data) errorToast(t("An error has occurred"));

        setLockedInfo(data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        checkLoader();
      });

    API_USER.balance
      .limit()
      .then((e) => {
        // FIXME может прийти от другой валюты,если на сервере не успел смениться
        if (DEBUG) console.log("limit deposit res", e);
        const data = e.data;
        if (!data) errorToast(t("An error has occurred"));
        checkOpen(data);
        // dispatch(setCurrency(data.currency));
        setMin(data.minAmount);
        setMax(data.maxAmount);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => checkLoader());
  }, [isOpen, isCrypto, currency]);

  useEffect(() => {
    if (isCrypto) dispatch(setLockBalanceClose(false));
  }, [isCrypto]);

  if (TEST_MODE) {
    return (
      <TopUpReal
        currency={currency}
        closeModal={!lockBonusClose && closeModal}
        maxInvoices={lockedInfo?.maxInvoices}
        max={max}
        min={min}
        setStep={setStep}
        setData={setData}
      />
    );
  }

  if (step === 1) {
    return (
      <LoaderContainer loader={loader} center>
        <ErrorBoundary>
          <TopUpBonus />
        </ErrorBoundary>
        {isCrypto ? (
          <TopUpCrypto currency={currency} min={min} setStep={setStep} closeModal={closeModal} />
        ) : (
          <TopUpReal
            currency={currency}
            closeModal={!lockBonusClose && closeModal}
            maxInvoices={lockedInfo?.maxInvoices}
            max={max}
            min={min}
            setStep={setStep}
            setData={setData}
          />
        )}
      </LoaderContainer>
    );
  }

  if (step === 2) {
    return (
      <>
        <TopUpSum currency={data.currency} value={data.amount} setStep={setStep} />
        <TopUpCards
          amount={data.amount}
          currency={data.currency}
          closeModal={!lockBonusClose && closeModal}
          setLockedInfo={setLockedInfo}
          setOpenPaymentPhone={setOpenPaymentPhone}
          setOpenPaymentCard={setOpenPaymentCard}
        />
      </>
    );
  }
}
