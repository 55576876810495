import { useEventListener } from "usehooks-ts";
import { useAppSelector } from "../../store/index.ts";

export const ENUM_TAWK_API = {
  show: "show",
  hide: "hide",
  visitor: "visitor",
  switch: "switch"
};

export default function useTawkController() {
  const isRunningGame = useAppSelector((state) => state.site.isRunningGame);

  useEventListener(ENUM_TAWK_API.show, () => {
    if (isRunningGame) return;
    window.Tawk_API?.showWidget?.();
    window.tawk_INFO.visible = true;
  });

  useEventListener(ENUM_TAWK_API.hide, () => {
    window.Tawk_API?.hideWidget?.();
    window.tawk_INFO.visible = false;
  });

  useEventListener(ENUM_TAWK_API.visitor, (e) => {
    const visitor = e.detail;

    if (window.Tawk_API) window.Tawk_API.visitor = visitor;
    window.tawk_INFO.visitor = visitor;
  });

  useEventListener(ENUM_TAWK_API.switch, (e) => {
    const { propertyId, widgetId, cb } = e.detail;

    if (window.Tawk_API?.switchWidget) {
      window.Tawk_API?.switchWidget?.({ propertyId, widgetId }, cb);
    }
  });
}
